import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ControlMessagesComponent } from './shared/control.message.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { UpperCaseTextDirective } from './shared/upperCase.directive'
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { ListadoClienteComponent } from './catalogo/cliente/listado-cliente/listado-cliente.component';
import { EdicionClienteComponent } from './catalogo/cliente/edicion-cliente/edicion-cliente.component';
import { Login2Component } from './authentication/login2/login2.component';
import { ErrorInterceptor, JwtInterceptor } from './_helpers/index';
import { ListadoFacturaComponent } from './catalogo/factura/listado-factura/listado-factura.component';
import { GenerarFacturaComponent } from './catalogo/factura/generar-factura/generar-factura.component';
import { ListadoProductoComponent } from './catalogo/producto/listado-producto/listado-producto.component';
import { EdicionProductoComponent } from './catalogo/producto/edicion-producto/edicion-producto.component';
import { FacturasCanceladasComponent } from './catalogo/factura/facturas-canceladas/facturas-canceladas.component';
import { ListadoEmpresaComponent } from './catalogo/empresa/listado-empresa/listado-empresa.component';
import { EdicionEmpresaComponent } from './catalogo/empresa/edicion-empresa/edicion-empresa.component';
import { DatosFacturacionComponent } from './catalogo/configuracion/datos-facturacion/datos-facturacion.component';
import { NoTimbradasComponent } from './catalogo/factura/no-timbradas/no-timbradas.component';
import { ReciboepagoComponent } from './catalogo/factura/reciboepago/reciboepago.component';
import { LoginComponent } from './authentication/login/login.component';
import { AjusteComponent } from './catalogo/configuracion/ajuste/ajuste.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    jqxGridComponent,
    ListadoClienteComponent,
    EdicionClienteComponent,
    Login2Component,
    LoginComponent,
    ListadoFacturaComponent,
    GenerarFacturaComponent,
    ListadoProductoComponent,
    EdicionProductoComponent,
    ControlMessagesComponent,
    UpperCaseTextDirective,
    FacturasCanceladasComponent,
    ListadoEmpresaComponent,
    EdicionEmpresaComponent,
    DatosFacturacionComponent,
    NoTimbradasComponent,
    ReciboepagoComponent,
    AjusteComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbCollapseModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes, { useHash: true }),
    PerfectScrollbarModule,
    AutocompleteLibModule,
    NgxExtendedPdfViewerModule,
    NgxSmartModalModule.forRoot()
  ],
  exports: [jqxGridComponent, ControlMessagesComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
