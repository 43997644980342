import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/facturaNueva/new',
    title: 'Generar Factura',
    icon: 'mdi mdi-file',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Facturas',
    icon: 'mdi mdi-arrange-send-backward',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/facturas/vigentes',
      title: 'Vigentes',
      icon: 'mdi mdi-gauge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }, {
      path: '/facturas/canceladas',
      title: 'Canceladas',
      icon: 'mdi mdi-gauge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }, {
      path: '/facturas/notimbradas',
      title: 'No timbradas',
      icon: 'mdi mdi-gauge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }, 
   /* {
      path: '/facturas/reciboepago',
      title: 'Recibo de Pago',
      icon: 'mdi mdi-gauge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }*/]
  },
  {
    path: '/clientes',
    title: 'Clientes',
    icon: 'mdi mdi-bullseye',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/productos',
    title: 'Productos',
    icon: 'mdi mdi-arrange-send-backward',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  }, 
  {
    path: '',
    title: 'Configuracion',
    icon: 'mdi mdi-arrange-send-backward',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
      path: '/configuracion/datosfacturacion',
      title: 'Datos Facturacion',
      icon: 'mdi mdi-gauge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    },
    {
      path: '/configuracion/ajuste',
      title: 'Ajuste',
      icon: 'mdi mdi-gauge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }
  ]
  }
];
