import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ClienteService, DataService, AuthenticationService, ValidationService, EmpresaService } from '../../../services/service.index';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ajuste',
  templateUrl: './ajuste.component.html',
  styleUrls: ['./ajuste.component.css']
})
export class AjusteComponent implements OnInit {
  frmEmpresa: any;
  usosCfdi: [];
  usuario: any;
  catRegimen: any;
  constructor(
    private formBuilder: FormBuilder,
    private _activateRouter: ActivatedRoute,
    private _route: Router,
    private _clienteService: ClienteService,
    private _dataService: DataService,
    private _authService: AuthenticationService,
    private _empresaService: EmpresaService) { }

  ngOnInit() {
    this.usuario = this._authService.getUser();
    console.log(this.usuario)
    this.frmEmpresa = this.crearFormulario();
    //this.getUsosCfdi();
    this.getRegimenFiscal();
    this._activateRouter.params.subscribe(param => {
      if (param['id'] != 'nuevo') {
        //Obtener el cliente
        Swal.showLoading();
        this._clienteService.obtenerPorId(param['id']).subscribe((data: any) => {
          /*this.usosCfdi.forEach(function (item: any) {
            if (item.codigoUsoCfdi === data.usoCfdi) {
              data.usoCfdi = item.id;
            }
          });*/
          this.frmEmpresa.patchValue(data);
          Swal.hideLoading();
          Swal.close();
        })
      }
    });
  }
  getRegimenFiscal() {
    this._empresaService.getRegimen().subscribe(data => {
      this.catRegimen = data;
    });
  }
  cambiarUsoCfdi(data) {

    this.usosCfdi.forEach(function (item: any) {
      if (item.codigoUsoCfdi === data) {

      }
    });
  }
  crearFormulario() {
    return this.formBuilder.group({
      razonSocial: [],
      rfc: ['', ValidationService.rfcValidator],
      regimenFiscal: ['1'],
      calle: [''],
      numInterior: [null],
      numExterior: [null],
      colonia: [null],
      codigoPostal: [null],
      localidad: [null],
      municipio: [null],
      telefono: [null, ValidationService.numberValidator],
      estado: [null],
      pais: [null],
      correo: [null, ValidationService.emailValidator],
      retieneImpuesto: ['0'],
      iva: ['0.16'],
      sendMail: ['1'],
      empresaId: [this.usuario.empresaId],
      id: [null]
    });
  }

  onSubmit() {
    //console.log(this.frmCliente.value);
    //hacer calculo si aplica iva o no
    Swal.fire({
      title: 'Procesando ...',
      showLoaderOnConfirm: true,
      onOpen: (data) => {
        // console.log('Al abrir modal...');
        Swal.showLoading();
        this._clienteService.almacenar(this.frmEmpresa.value)
          .subscribe((resp: any) => {
            if (!resp.response) {
              let msj = '';
              if (resp.errors) {
                for (let item of resp.errors) {
                  msj += `<br>${item}`;
                }
              }
              Swal.showValidationMessage(
                `<div class="alert alert-danger" role="alert">Error en el proceso: ${msj}</div>`
              );
            }
            Swal.hideLoading();
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Producto registrado',
              showConfirmButton: false,
              timer: 1500
            });
            this.reset();
            return resp;
          },
            (err: any) => {
              Swal.hideLoading();
              Swal.close();
              Swal.showValidationMessage(
                `Error en el proceso: ${err}`
              )
            });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }
  reset() {
    this.frmEmpresa.reset();
    this.frmEmpresa = this.crearFormulario();
    //this.router.navigate(['/productos']);
  }
}
