import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { Login2Component } from './authentication/login2/login2.component';
import { AuthGuard } from './_guards/auth.guard';
import { ListadoClienteComponent } from './catalogo/cliente/listado-cliente/listado-cliente.component';
import { ListadoFacturaComponent } from './catalogo/factura/listado-factura/listado-factura.component';
import { GenerarFacturaComponent } from './catalogo/factura/generar-factura/generar-factura.component';
import { ListadoProductoComponent } from './catalogo/producto/listado-producto/listado-producto.component';
import { EdicionProductoComponent } from './catalogo/producto/edicion-producto/edicion-producto.component';
import { EdicionClienteComponent } from './catalogo/cliente/edicion-cliente/edicion-cliente.component';
import { FacturasCanceladasComponent } from './catalogo/factura/facturas-canceladas/facturas-canceladas.component';
import { EdicionEmpresaComponent } from './catalogo/empresa/edicion-empresa/edicion-empresa.component';
import { ListadoEmpresaComponent } from './catalogo/empresa/listado-empresa/listado-empresa.component';
import { DatosFacturacionComponent } from './catalogo/configuracion/datos-facturacion/datos-facturacion.component';
import { NoTimbradasComponent } from './catalogo/factura/no-timbradas/no-timbradas.component';
import { ReciboepagoComponent } from './catalogo/factura/reciboepago/reciboepago.component';
import { LoginComponent } from './authentication/login/login.component';
import { AjusteComponent } from './catalogo/configuracion/ajuste/ajuste.component';

export const Approutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/starter', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'clientes',
        component: ListadoClienteComponent
      },
      {
        path: 'facturas/vigentes',
        component: ListadoFacturaComponent
      }, {
        path: 'productos',
        component: ListadoProductoComponent
      },
      {
        path: 'producto/edicion/:id',
        component: EdicionProductoComponent
      },
      {
        path: 'cliente/edicion/:id',
        component: EdicionClienteComponent
      },
      {
        path: 'facturaNueva/:id',
        component: GenerarFacturaComponent
      },
      {
        path: 'facturas/canceladas',
        component: FacturasCanceladasComponent
      }, {
        path: 'facturas/notimbradas',
        component: NoTimbradasComponent
      },
      {
        path: 'facturas/reciboepago',
        component: ReciboepagoComponent
      }
      ,
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
      {
        path: 'empresa/listado',
        component: ListadoEmpresaComponent
      },
      {
        path: 'empresa/edicion',
        component: EdicionEmpresaComponent
      },
      {
        path: 'configuracion/datosfacturacion',
        component: DatosFacturacionComponent
      },
      {
        path: 'configuracion/ajuste',
        component: AjusteComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/starter'
  }
];
