import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class EmpresaService {
    totalProductores: number = 0;
    public url = URL_SERVICIOS + 'empresa';
    constructor(
        private http: HttpClient
    ) { }
    getEmpresa(id:number){
        if (id === 0) {
            return of([]);
        }
        return this.http
            .get(`${URL_SERVICIOS}empresa/${id}`).pipe(
                map(response => response)
            );
    }
    getSucursal(id: number) {
        if (id === 0) {
            return of([]);
        }
        return this.http
            .get(`${URL_SERVICIOS}sucursal/${id}`).pipe(
                map(response => response)
            );
    }
    getRegimen() {
        return this.http
            .get(this.url + '/catRegimenFiscal').pipe(
                map(response => response)
            );
    }
    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/buscar?busqueda=' + term).pipe(
                map(response => response)
            );
    }
    almacenar(data: any) {
        if (data.id != null) {
            //actualizando
            let url = URL_SERVICIOS + 'empresa/actualizar/' + data.id;
            return this.http.put(url, data)
                .pipe(
                    map((response: any) => response)
                );
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'empresa', data)
                .pipe(
                    map((response: any) => response)
                );
        }
    }
    obtenerPorId(id: string) {
        if (id === null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'empresa/' + id).pipe(
                map(response => response)
            );
    }

    obtenerDetalle(id: number) {
        if (id === 0) {
            return of([]);
        }
        return this.http
            .get(this.url + '/detalle/' + id).pipe(
                map(response => response)
            );
    }

    obtenerPorRfc(rfc: string) {
        if (rfc === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/obtenerPorRfc/' + rfc).pipe(
                map(response => response)
            );
    }

}
