import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../config/config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private token: any;
    private usuario: any;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }
    public getUser() {
        let payload = JSON.parse(atob(this.currentUserSubject.value.token.split('.')[1]));
        return payload.data;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${URL_SERVICIOS}auth/login`, { correoElectronico:email, password, hereSession: true })
            .pipe(map(data => {
                localStorage.removeItem('currentUser');
                console.log(data)
                if (data && data.result) {
                    let dataStorage = {
                        token: data.result.token,
                        user: data.result.usuario
                    }
                    localStorage.setItem('currentUser', JSON.stringify(dataStorage));
                    //document.cookie = `token=${data.token};`;
                    this.currentUserSubject.next(dataStorage);
                }
                return data;
            }));
    }


    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

}